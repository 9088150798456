$.fn.switchChevron = function (cElement, cName01, cName02) {
  if ($(cElement).hasClass("" + cName01 + "")) {
      $(cElement).removeClass("" + cName01 + "").addClass("" + cName02 + "");
  } else {
      $(cElement).removeClass("" + cName02 + "").addClass("" + cName01 + "");
  }
}
$(".preventEL").click(function (event) {
  event.preventDefault();
});

$( document ).ready(function() {
  /*console.log('TEST');*/

    $(window).scroll(function () {
        /*console.log($(window).scrollTop())*/
        var topBarHeight = $(".top-bar").height();
        var mainNavHeight = $(".main-nav").height();
        var totalHeaderHeight = (topBarHeight+mainNavHeight);
        if ($(window).scrollTop() > (totalHeaderHeight - 1)) {
            $('.navbar').addClass('navbar-fixed');
            $(".top-bar").hide();
        }
        if ($(window).scrollTop() < totalHeaderHeight) {
            $('.navbar').removeClass('navbar-fixed');
            if($(window).width() > 640){
                $(".top-bar").hide();
            }
        }
    });
  /*navbar-toggler */
  $(".navbar-toggler").click(function(){
    $(this).children(".navbar-toggler-icon").toggleClass("bg-a-blue color-white");
  });
  $(".languages .trigger").click(function(){
    var changeCurIcon = $(this).find("i.fa");
    $.fn.switchChevron(changeCurIcon, "fa-chevron-down", "fa-chevron-up");
    //console.log($(this).next());
    $(this).next("ul").toggleClass("active");
  });
  /* ACCORDIONS */
  //$(".accordion-item").first().addClass("active").find("ico").toggleClass("fa-chevron-down fa-chevron-up");
  $(".form-item").on('change', function (event) {
      event.preventDefault();
      if (!$(this).closest('.accordion-item').find("i.icon").hasClass('selected')) {
          $(this).closest('.accordion-item').find("i.icon").toggleClass("selected");
      }
    
    $(this).parent(".accordion-item").toggleClass("active");
  });
  /*Multi item carousel */ 
  /** */

    $(".sb-acc-trigger").click(function (event) {
        event.preventDefault();
        $(this).find("i.icon").toggleClass("selected");
        $(this).parent(".accordion-item").toggleClass("active");
    });

    // ===== Scroll to Top ==== 
    $(window).scroll(function() {
        if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
            $('#return-to-top').fadeIn(200);    // Fade in the arrow
        } else {
            $('#return-to-top').fadeOut(200);   // Else fade out the arrow
        }
    });
    $('#return-to-top').click(function() {      // When arrow is clicked
        $('body,html').animate({
            scrollTop : 0                       // Scroll to top of body
        }, 500);
    });
}); 